.bio-container {
    text-align: left;
    padding: 20px;
  }
  
  .profile-image {
    width: 200px; /* Adjust the size as needed */
    height: auto;
    margin: 20px 0;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  