.home-container {
    text-align: left; /* Align content to the left */
    padding: 20px;
  }
  
  .home-image {
    width: 200px; /* Adjust the size as needed */
    height: auto;
    margin: 20px 0;
  }
  
  footer {
    margin-top: 20px;
    font-size: 14px;
    color: #555;
  }
  