body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
}

nav {
  width: 200px;
  background-color: #f5f5f5; /* Whitish gray color */
  color: black;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

nav ul li {
  width: 100%;
}

nav ul li a {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  text-align: center;
}

nav ul li a:hover {
  background-color: #ddd; /* Slightly darker gray for hover */
}

.App {
  margin-left: 200px;
  padding: 20px;
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
}

h1 {
  font-size: 24px;
  color: #333;
}

p {
  font-size: 16px;
  color: #555;
}

ul {
  padding: 0;
  list-style-type: none;
}

ul li {
  margin: 10px 0;
}

ul li a {
  color: #1a73e8;
  text-decoration: none;
}

ul li a:hover {
  text-decoration: underline;
}

.essay-list,
.essay-content {
  max-width: 600px;
  margin: 0 auto;
}

.menu-toggle {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* Close button inside the nav */
.close-btn {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* Mobile view */
@media (max-width: 768px) {
  nav {
    transform: translateX(-100%);
  }

  nav.open {
    transform: translateX(0);
  }

  .App {
    margin-left: 0;
  }

  .menu-toggle {
    display: block;
  }

  .close-btn {
    display: block;
  }
}
